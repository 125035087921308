import { request } from './request' // 引入封装好的axios对象
import store from '../store'

// 手机号登录
export function login(account, password) {
  return request({
    url: '/api/login/login',
    method: 'post',
    params: {
      account,
      password
    }
  })
}

// 手机验证码登录
export function mobilelogin(mobile, captcha) {
  return request({
    url: '/api/login/mobilelogin',
    method: 'post',
    params: {
      mobile,
      captcha
    }
  })
}

// 获取手机注册验证码
export function getCode(mobile, event) {
  return request({
    url: '/api/login/code',
    method: 'post',
    params: {
      mobile,
      event
    }
  })
}

// 验证图片验证码
export function captchaCode(imgCaptcha) {
  return request({
    url: '/api/login/getImages',
    method: 'post',
    params: {
      captcha: imgCaptcha
    }
  })
}

// 注册
export function register(mobile, password, code, key) {
  return request({
    url: '/api/login/register',
    method: 'post',
    params: {
      mobile,
      password,
      code,
      key
    }
  })
}

// 重置密码
export function chongzhi(mobile, newpassword, captcha) {
  return request({
    url: '/api/login/resetpwd',
    method: 'post',
    params: {
      mobile,
      newpassword,
      captcha
    }
  })
}

// 更换头像
export function getPicer(avater) {
  return request({
    url: '/api/balance/profile',
    method: 'post',
    params: {
      avater
    }
  })
}

// 首页商品列表
export function getIndexGoods(params) {
  return request({
    url: '/api/Index/get_goods_info',
    method: 'post',
    params
  })
}

// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage(params) {
  return request({
    url: '/api/goods/get_all_storage',
    method: 'post',
    params
  })
}

// 根据平台筛选仓库
export function choosePlatform(platform) {
  return request({
    url: '/api/goods/choose_platform',
    method: 'post',
    params: {
      platform
    }
  })
}

// 所有商品
export function getAllGoods(params) {
  return request({
    url: '/api/goods/get_all_goods',
    method: 'post',
    params
  })
}
// 所有分类
export function getCateList(params) {
  return request({
    url: '/api/index/goodsTypes',
    method: 'post',
    params
  })
}
// 商品图片
// export function PackageImg(params) {
//   const fd = new FormData()
//   fd.append('file', params)
//   return request({
//     url: '/api/matter/getPackageImg',
//     method: 'post',
//     headers: {
//       'Content-Type': 'multipart/form-data; charset=UTF-8'
//     },
//     data: fd
//   })
// }
// 源文件
export function ImgFiles(params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/matter/getImgs',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 商品筛选
export function goodsSearchGs(params) {
  return request({
    url: '/api/goods/search_gs',
    method: 'post',
    params
  })
}

// 淘宝店铺绑定
export function storeBind() {
  return request({
    url: '/api/tbbandstore/permit',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取店铺绑定信息
export function getBandStore() {
  return request({
    url: '/api/tbbandstore/getUserstore',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 删除店铺绑定
export function getBandDelete(params) {
  return request({
    url: '/api/tbbandstore/storeDelete',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 获取店铺名称
export function getStoreName(data) {
  return request({
    url: '/api/tbbandstore/getBandStore',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 根据淘宝店铺名称获取订单列表
export function getTaoBaoOrderList(data) {
  return request({
    url: '/api/tbbandstore/getOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function getpddOrderList(data) {
  return request({
    url: '/api/bandstore/getOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 改变店铺自动发货状态
export function autoChange(data) {
  return request({
    url: '/api/tbbandstore/dev_button',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 创建订单和包裹
export function createOrder(data) {
  return request({
    url: '/api/order/create_order',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 发件地址添加
export function addArea(data) {
  return request({
    url: '/api/area/area_add',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 发件地址查询
export function getAreaList() {
  return request({
    url: '/api/area/area_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取订单列表
export function getOrderList(params) {
  return request({
    url: '/api/order/orderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 首页广告位
export function getAdsInfo(params) {
  return request({
    url: '/api/Index/get_ads_info',
    method: 'post',
    params
  })
}

// 首页配置信息
export function getSiteInfo(params) {
  return request({
    url: '/api/Index/get_site_info',
    method: 'post',
    params
  })
}

// 配置验证码信息
export function getSitepassword(event) {
  return request({
    url: '/api/Index/get_site_info',
    method: 'post',
    params: {
      event
    }
  })
}
// 包裹筛选
export function getPackageList(params) {
  return request({
    url: '/api/order/packageList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 获取全部系统公告
export function getNoticeAll(params) {
  return request({
    url: '/api/notice/noticeAll',
    method: 'post',
    params
  })
}

// 系统公告分类筛选
export function getNoticeSelect(params) {
  return request({
    url: '/api/notice/noticeSelect',
    method: 'post',
    params
  })
}

// 工单写入
export function matterWrite(params) {
  return request({
    url: '/api/matter/matterWrite',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 工单上传图片
export function imgsHome(params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/matter/imgsHome',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 工单筛选
export function matterSelect(params) {
  return request({
    url: '/api/matter/matterSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 改变工单状态
export function matterStatus(params) {
  return request({
    url: '/api/matter/matterStatus',
    method: 'post',
    params
  })
}

// 用户基本信息
export function getUserMoney() {
  return request({
    url: '/api/login/userInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 余额支付
export function orderPay(id, key) {
  return request({
    url: '/api/order/order_pay',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      id,
      key
    }
  })
}

// 改变订单状态
export function changeOrderStatus(params) {
  return request({
    url: '/api/order/change_order_status',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 发件地址删除
export function areaDel(id) {
  return request({
    url: '/api/area/area_del',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      area_id: id
    }
  })
}

// 默认发件地址选择
export function homeArea(id) {
  return request({
    url: '/api/area/homeArea',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      area_id: id
    }
  })
}

// 修改密码
export function resetpwd(password) {
  return request({
    url: '/api/balance/resetpwd',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      password: password
    }
  })
}

// 修改支付宝账号
export function resetzfb(zfb, realname) {
  return request({
    url: '/api/balance/resetZfb',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      zfb: zfb,
      real_name: realname
    }
  })
}

// 邀请的奖励信息筛选
export function rewardSelect(params) {
  return request({
    url: '/api/invite/rewardSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 符合状态的子订单数量
export function statusNum(params) {
  return request({
    url: '/api/order/status_num',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 充值
export function topuprice(amount, payType) {
  return request({
    url: '/api/api/pay',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''

    },
    params: {
      amount,
      payType
    }
  })
}

// 工单记录
export function resolve(params) {
  return request({
    url: '/api/matter/resolve',
    method: 'post',
    params
  })
}

// 余额信息
export function moneyInfo() {
  return request({
    url: '/api/balance/moneyInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请人ID
export function inviteHttp() {
  return request({
    url: '/api/invite/inviteHttp',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请的用户信息筛选
export function inviteSelect(params) {
  return request({
    url: '/api/invite/inviteSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 邀请的全部用户信息
export function selectAll() {
  return request({
    url: '/api/invite/selectAll',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 修改手机号
export function changemobile(mobile, captcha) {
  return request({
    url: '/api/login/changemobile',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      mobile,
      captcha
    }
  })
}

// 金钱变动列表
export function moneylist(params) {
  return request({
    url: '/api/balance/moneylist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 引导用户登录授权
export function shouquan() {
  return request({
    url: '/api/agiso/shouquan',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 提现
export function balanceApply(params) {
  return request({
    url: '/api/balance/apply',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 表格导入
export function importExcel(params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/excel/import_excel',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 快递停发区域
export function stopArea(id) {
  return request({
    url: '/api/area/stoparea',
    method: 'post',
    params: {
      deliver_id: id
    }
  })
}

// 常见工单问题
export function matterResolve() {
  return request({
    url: '/api/matter/matterResolve',
    method: 'post'
  })
}
