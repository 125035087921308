import axios from 'axios' // 引入axios
import store from '../store/actions'
import router from '../router/index'
import { Message } from 'element-ui' // 引入element-ui Message组件

export function request (config) {
  // 创建axios实例
  const instance = axios.create({
    baseURL: 'http://yuanda.fxb-dc.cn',
    timeout: 5000
  })
  // 请求拦截器
  instance.interceptors.request.use(
    config => {
      return config
    },
    err => {
      return err
    }
  )
  // 响应拦截器
  instance.interceptors.response.use(
    res => {
      return res.data
    },
    err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            // 401 清除token信息并跳转到登录页面
            store.commit('logout')
            router.replace({
              path: 'login',
              query: { redirect: router.currentRoute.fullPath }
            })
        }
      }
      Message.error('网络错误，请稍后重试')
      return err
    }
  )
  // 发送真正的网络请求
  return instance(config)
}
